<template>
  <draggable class="dragArea" tag="ul" :list="tasks" :group="{ name: 'g1' }" v-bind="dragOptions"
             @start="drag = true"
             @end="drag = false"
             @change="updateCategoriesOrder"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
      <li v-for="el in tasks" :key="el.name">
        <p @click="set_category(el.token, el.name)" :class="`${currentToken === el.token ? 'is_active' : ''}`">
          {{ el.name }}
          <span class="float-right d-none">
            <i class="ri-pencil-fill" @click="$emit('edit_category', el.name, el.token)" v-if="el.parent === 0"></i>
            <i class="ri-add-box-fill" @click="$emit('add_subcategory', el.token)" v-if="el.parent === 0" style="color: darkblue"></i>
            <i class="ri-close-circle-fill" @click="$emit('delete_category', el.token)" v-if="el.parent === 0" style="color: red"></i>
            <i class="ri-pencil-fill" @click="edit_category(el.name, el.token)" v-if="el.parent !== 0"></i>
            <i class="ri-close-circle-fill" @click="delete_category(el.token)" v-if="el.parent !== 0" style="color: red"></i>
          </span>
        </p>
        <nested-draggable
          :tasks="el.tasks"
          v-on="$listeners"
        />
      </li>
    </transition-group>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['tasks'],
  components: {
    draggable
  },
  name: 'nested-draggable',
  data () {
    return {
      drag: false,
      currentToken: localStorage.getItem('currentToken') || ''
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    edit_category (name, token) {
      this.$emit('edit_category', name, token)
    },
    delete_category (token) {
      this.$emit('delete_category', token)
    },
    updateCategoriesOrder () {
      this.$emit('change_order', name)
    },
    set_category (token, name) {
      const localToken = localStorage.getItem('currentToken')
      console.log(localToken, token)
      if (localToken === token) {
        localStorage.removeItem('currentToken')
        this.currentToken = ''
      } else {
        localStorage.setItem('currentToken', token)
        this.currentToken = token
      }

      this.$emit('set_category', this.currentToken, name)
    }
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 1px;
}
.is_active{
  background: #13b8ba !important;
}
</style>
