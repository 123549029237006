<template>
  <v-app>
    <b-container fluid>
    <b-modal size="xl" id="addProduct" :title="$t('customer_.add_product')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <input type="hidden" v-model="productToken" >
        <div class="col-sm-12">
          <div class="row p-0">
            <div class="col-sm-8">
              {{ $t('customer_.product_name') }}
              <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.product_name')" v-model="productName" >
            </div>
            <div class="col-sm-4">
              {{ $t('customer_.product_active') }} <br>
              <b-form-group>
                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                  <div class="custom-switch-inner">
                    <input type="checkbox" class="custom-control-input bg-primary" id="productActive" v-model="productActive">
                    <label class="custom-control-label" for="productActive" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                    </label>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
          {{ $t('customer_.product_description') }}
          <br>
          <vue-editor id="editor" v-model="productDescription"></vue-editor>
          <br>
          {{ $t('customer_.product_specs') }}
          <br>
          <v-combobox
            dense
            clearable
            multiple
            chips
            v-model="specValues"
            :items="specSelectedValues"
            item-text="name"
            @change="changeSpecs"
          ></v-combobox>
          <div class="row p-0">
            <div class="new_specs col-sm-6" v-if="currentSpec !== ''">
              {{ $t('customer_.spec_name') }} <br>
              <input type="text" v-model="currentSpec" class="form-control round mb-4"/>
              {{ $t('customer_.spec_value') }} <br>
                <v-combobox
                  dense
                  clearable
                  multiple
                  chips
                  v-model="newSpecValue"
                  :items="specDefaultValues"
                  item-text="name"
                  @change="setSpecValue"
                ></v-combobox>
                <b-button variant="warning" class="float-right" @click="deleteTag">{{ $t('customer_.product_delete_tag') }}</b-button>
              <b-button variant="primary" class="float-right" @click="saveTag">{{ $t('customer_.product_save_tag') }}</b-button>
            </div>
            <div class="existing_specs col-sm-6">
              <div v-for="(item,index) in specList" :key="index">
                {{ item.name }}: {{ item.value }}
                <i class="ri-pencil-line" @click="editspec(item)"></i>
                <i class="ri-close-circle-fill" @click="deletespec(item)"></i>
              </div>
            </div>
          </div>
          <br>
          {{ $t('customer_.product_pictures') }} <small>{{ $t('customer_.product_pictures_limit') }}</small>
          <br>
          <div id="product_images">
            <draggable
              class="list-group"
              tag="ul"
              v-model="transitionList"
              v-bind="dragOptions1"
              @start="drag = true"
              @end="drag = false"
              :list="selectedImages"
            >
                <div v-for="(item,index) in selectedImages" :key="index" >
                  <b-img :src="item" class="product_image" />
                  <i class="ri-close-circle-fill" @click="delete_image(item)"></i>
                </div>
            </draggable>

            <div class="add_the_image" v-show="selectedImages.length < 5">
              <input type="file" multiple ref="fileNew" @change="previewImage()" class="position-absolute" accept="image/*" style="opacity: 0; left: 0" />
              +
            </div>
          </div>
          <br>
          {{ $t('customer_.shop_categories') }} :
          <v-combobox
            dense
            clearable
            multiple
            chips
            v-model="chips"
            :items="someItems"
            item-text="name"
          ></v-combobox>
          <br>
          <div class="row p-0 m-0">
            <div class="col-sm-6 p-0" style="position:relative">
              {{ $t('customer_.product_price') }} :<br>
              <input type="number" class="form-control round mb-4" v-model="productPrice"/>
              <span class="serviceCurrency">{{ currency }}</span>
            </div>
            <div class="col-sm-6 p-0" style="position:relative">
              {{ $t('customer_.product_discount_price') }} :<br>
              <input type="number" class="form-control round mb-4" v-model="productDiscountPrice"/>
              <span class="serviceCurrency">{{ currency }}</span>
            </div>
          </div>
          {{ $t('customer_.product_stock') }} :<br>
          <input type="number" class="form-control round mb-4" v-model="productStock" >
          <br>
          {{ $t('customer_.product_affiliate') }} :<br>
          <input type="text" class="form-control round mb-4" v-model="productAffiliate" >
          <br>
          {{ $t('customer_.product_review') }} :<br>
          <b-form-group>
            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
              <div class="custom-switch-inner">
                <input type="checkbox" class="custom-control-input bg-primary" id="cashpayment" v-model="productReview">
                <label class="custom-control-label" for="cashpayment" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                </label>
              </div>
            </div>
          </b-form-group>
          <br>
          <b-button variant="primary" class="float-right" @click="saveProduct">{{ $t('customer_.save_product') }}</b-button>

        </div>
      </div>
    </b-modal>
    <b-modal id="addCategory" :title="$t('customer_.add_category')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-12">
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.category_name')" v-model="addCategoryName" >
          <b-button variant="primary" class="float-right" @click="addTheCategory">{{ $t('customer_.add_category') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="editCategory" :title="$t('customer_.edit_category')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-12">
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.category_name')" v-model="addCategoryNameEdit" >
          <input type="hidden" v-model="addCategoryToken" />
          <b-button variant="primary" class="float-right" @click="addTheCategoryEdit">{{ $t('customer_.edit_category') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="addSubcategory" :title="$t('customer_.add_subcategory')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-12">
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.category_name')" v-model="addSubcategoryName" >
          <input type="hidden" v-model="addSubcategoryToken" />
          <b-button variant="primary" class="float-right" @click="addTheSubcategory">{{ $t('customer_.add_subcategory') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:body>
            <div class="row">
              <div class="col-sm-3 border-right">
                <h4 class="mb-2">Categories
                  <b-button variant="primary" class="float-right" @click="addCategory">{{ $t('customer_.add_category') }}</b-button>
                </h4>
                <br>
                <div class="menu-container">
                  <nested-draggable
                    :tasks="nestedList"
                    v-bind="dragOptions"
                    v-on:edit_category="editCategory"
                    v-on:add_subcategory="addSubcategory"
                    v-on:delete_category="deleteCategory"
                    v-on:change_order="updateCategoriesOrder"
                    v-on:set_category="setCategory"
                  />
                </div>
              </div>
              <div class="col-sm-9">
                <h4 class="mb-2">Products
                  <b-button variant="primary" class="float-right" @click="addProduct">{{ $t('customer_.add_product') }}</b-button>
                </h4>
                <div style="width: 100%">
                  <v-text-field
                    v-model="search"
                    append-icon="ri-search-line"
                    :label="$t('employees_list.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="rowData"
                  :search="search"
                  :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
                >
                  <template v-slot:item.name="{ item }">
                    <img :src="item.img" style="object-fit: cover; width:50px; height:50px;" class="rounded mr-3"/>{{ item.bookingsValue }}
                    {{ item.name }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <button type="button" class="btn btn-default text-success" @click="editProduct(item)">
                      <i class="ri-pencil-line"></i> {{ $t('employees_list.edit') }}
                    </button>
                    <button type="button" class="btn btn-default text-danger" @click="removeProduct(item)">
                      <i class="ri-delete-bin-line"></i> {{ $t('employees_list.delete') }}
                    </button>
                  </template>
                </v-data-table>
              </div>

            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  </v-app>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import NestedDraggable from '../../components/core/dragdrop/nested-draggable'
import draggable from 'vuedraggable'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'Products',
  components: { draggable, NestedDraggable, VueEditor },
  mounted () {
    core.index()
    localStorage.removeItem('currentToken')
    this.getCategories()
    this.getProducts()
  },
  data () {
    return {
      currentSpec: '',
      specValues: [],
      newSpecValue: [],
      specValue: '',
      specDefaultValues: [],
      specSelectedValues: [],
      specList: [],
      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      nonce: 1,
      model: [],
      currency: 'RON',
      chips: [],
      someItems: [],
      drag: false,
      selectedImages: [],
      productName: '',
      productToken: '',
      productActive: true,
      productDescription: '',
      productPrice: '',
      productDiscountPrice: '',
      productStock: 1,
      productAffiliate: '',
      productReview: '',
      dragOptions: '',
      addCategoryName: '',
      addCategoryNameEdit: '',
      addCategoryToken: '',
      addSubcategoryName: '',
      addSubcategoryToken: '',
      nestedList: [],
      transitionList: [],
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('employees_list.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('booking.price'), value: 'price' },
        { text: this.$t('employees_list.actions'), value: 'actions' }
      ],
      search: ''
    }
  },
  methods: {
    editspec (spec) {
      this.currentSpec = spec.name
      this.newSpecValue = spec.value.split(',')
    },
    deletespec (spec) {
      const index = this.specList.indexOf(spec)
      this.specList.splice(index, 1)
      this.currentSpec = ''
    },
    setSpecValue () {
      this.specValue = this.newSpecValue
    },
    deleteTag () {
      if (this.specList.length > 0) {
        for (let i = 0; i < this.specList.length; i++) {
          if (this.specList[i].name === this.currentSpec) {
            this.deletespec(this.specList[i])
          }
        }
      }
    },
    saveTag () {
      let counter = -1
      if (this.specList.length > 0) {
        for (let i = 0; i < this.specList.length; i++) {
          if (this.specList[i].name === this.currentSpec) {
            counter = i
          }
        }
      }
      if (counter === -1) {
        if (this.specValue.join !== '') {
          this.specList.push(
            {
              name: this.currentSpec,
              value: this.specValue.join()
            }
          )
        }
      } else {
        this.specList[counter].value = this.specValue.join()
      }
    },
    changeSpecs (value) {
      const currentValue = value[value.length - 1]
      if (!this.specList.includes(currentValue)) {
        this.currentSpec = currentValue
      } else {
        this.currentSpec = ''
      }
      this.newSpecValue = []
    },
    setCategory (token, name) {
      // display products
      if (token === '') {
        this.getProducts()
      } else {
        this.getProductsByCategory(token)
      }
      this.chips = [name]
    },
    updateCategoriesOrder () {
      const dataPost = {
        list: this.nestedList
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_CHANGE_CATEGORY_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getCategories()
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addSubcategory (token) {
      this.addSubcategoryToken = token
      this.$bvModal.show('addSubcategory')
    },
    deleteCategory (token) {
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_category'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              token: token
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_CATEGORY, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  this.getCategories()
                  core.showSnackbar('success', this.$t('customer_.category_deleted'))
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    },
    editCategory (name, token) {
      this.addCategoryNameEdit = name
      this.addCategoryToken = token
      this.$bvModal.show('editCategory')
    },
    addTheSubcategory () {
      const dataPost = {
        name: this.addSubcategoryName,
        token: this.addSubcategoryToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_SUBCATEGORY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getCategories()
            this.$bvModal.hide('addSubcategory')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addTheCategoryEdit () {
      const dataPost = {
        name: this.addCategoryNameEdit,
        token: this.addCategoryToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_EDIT_CATEGORY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getCategories()
            this.$bvModal.hide('editCategory')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addTheCategory () {
      const dataPost = {
        name: this.addCategoryName
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_CATEGORY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getCategories()
            this.$bvModal.hide('addCategory')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addCategory () {
      this.$bvModal.show('addCategory')
    },
    getCategories () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_CATEGORIES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.nestedList = response.data.categories
            this.someItems = response.data.categoryList
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addProduct () {
      this.productToken = ''
      this.productName = ''
      this.productActive = true
      this.productDescription = ''
      this.selectedImages = []
      this.productPrice = ''
      this.productDiscountPrice = ''
      this.productStock = 1
      this.productAffiliate = ''
      this.productReview = true
      this.specList = []
      this.chips = []
      this.$bvModal.show('addProduct')
    },
    getProducts () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PRODUCTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.product
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getProductsByCategory (token) {
      const dataPost = {
        company: this.companyToken,
        category: token
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PRODUCTS_BY_CATEGORY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.product
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    editProduct (item) {
      this.productToken = item.token
      this.productName = item.name
      this.productActive = item.active === 1
      this.productDescription = item.description
      this.selectedImages = item.img.split(',')
      this.productPrice = item.price
      this.productDiscountPrice = item.priceDiscount
      this.productStock = item.stock
      this.productAffiliate = item.affiliate
      this.productReview = item.review === 1
      this.specList = JSON.parse(item.tags)
      this.chips = item.category.split(',')
      this.$bvModal.show('addProduct')
    },
    removeProduct (item) {
      const theToken = item.token
      if (item.admin === '1') {
        alert(this.$t('customer_.cannot_delete_admin'))
        return
      }
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_employee'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              employee: theToken
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_EMPLOYEE, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  core.showSnackbar('success', 'User has been removed successfully!')
                  this.$router.go()
                } else if (response.data.status === 'fail') {
                  core.showSnackbar('success', 'There was a problem!')
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    },
    saveProduct () {
      if (this.productName === '') {
        core.showSnackbar('error', this.$t('customer_.product_missing_name'))
      } else if (this.productDescription === '') {
        core.showSnackbar('error', this.$t('customer_.product_missing_description'))
      } else if (this.selectedImages.length === 0) {
        core.showSnackbar('error', this.$t('customer_.product_missing_image'))
      } else if (this.productPrice === '') {
        core.showSnackbar('error', this.$t('customer_.product_missing_price'))
      } else if (this.productStock === '') {
        core.showSnackbar('error', this.$t('customer_.product_missing_stock'))
      } else {
        const formData = new FormData()
        if (this.productToken) {
          formData.append('token', this.productToken)
        }
        formData.append('name', this.productName)
        formData.append('active', this.productActive)
        formData.append('description', this.productDescription)
        formData.append('images', this.selectedImages)
        formData.append('price', this.productPrice)
        if (this.productDiscountPrice) {
          formData.append('discountprice', this.productDiscountPrice)
        }
        formData.append('currency', 'RON')
        formData.append('stock', this.productStock)
        formData.append('affiliate', this.productAffiliate)
        formData.append('review', this.productReview)
        if (this.specList.length > 0) {
          formData.append('tags', JSON.stringify(this.specList))
        }
        if (this.chips.length > 0) {
          formData.append('category', JSON.stringify(this.chips))
        }
        Axios.post(API.API_ADD_PRODUCT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (response.data.status === 'success') {
              this.getProducts()
              core.showSnackbar('success', this.$t('customer_.product_saved'))
              this.$bvModal.hide('addProduct')
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    previewImage () {
      const reader = new FileReader()
      function readFile (index, thisOne) {
        if (index >= thisOne.$refs.fileNew.files.length) return
        const file = thisOne.$refs.fileNew.files[index]
        reader.onload = function (e) {
          if (thisOne.selectedImages.length < 5) {
            thisOne.selectedImages.push(e.target.result)
            readFile(index + 1, thisOne)
          }
        }
        reader.readAsDataURL(file)
      }
      readFile(0, this)
    },
    delete_image (item) {
      const filtered = this.selectedImages.filter(function (value, index, arr) {
        return item !== value
      })
      this.selectedImages = filtered
    }
  },
  computed: {
    dragOptions1 () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>
<style>
/*.fa-caret-down:before {*/
/*  content: "\25BA" !important;*/
/*}*/
.menu-container > ul > span > li > p{
  background: #cbcbcb;
  margin-bottom: 0px !important;
  padding: 10px;
  color: black;
  cursor: pointer;
  border-bottom: 1px solid grey;
}
.menu-container > ul > span > li:hover  p > .d-none {
  display: block !important;
}
.menu-container > ul > span > li .dragArea > span > li > p{
  margin: 0;
  padding: 10px;
  padding-left: 20px;
  background: #f4f4f4;
  color: black;
  cursor: pointer;
  border-bottom: 1px solid grey;
}
.add_the_image,
#product_images .list-group > div{
  width: 100px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 80px;
  text-align: center;
  line-height: 100px;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.add_the_image {
  position: relative;
}
#product_images .list-group {
  display: inline-block;
  width: auto;
}
.product_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-size: 10px;
  line-height: 10px !important;
  vertical-align: top !important;
}
.list-group .ri-close-circle-fill {
  color: red;
  position: absolute;
  right: 0;
  font-size: 20px;
  top: 0;
  line-height: 23px;
}
.fas.fa-times-circle:before{
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}
.serviceCurrency{
  position: absolute;
  right: 30px;
  top: 22px;
  font-weight: bold;
  color: #505050;
}
</style>
